import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Settings from "src/stores/Settings";
import { colors, rem, fontWeights, lineHeight } from "src/styles/variables";
import { LabelXS, SubHeaderS } from "src/atoms/Typography";
import { DIMENSIONS, ICONS } from "./constants";
import FactIcon from "src/atoms/Vectors/Card/Fact";
import PlayIcon from "src/atoms/Vectors/Card/Play";
import PodcastIcon from "src/atoms/Vectors/Card/Podcast";
import RecipeIcon from "src/atoms/Vectors/Card/Recipe";
import ReadIcon from "src/atoms/Vectors/Card/Read";

const IconMap = {
  [ICONS.article]: ReadIcon,
  [ICONS.video]: PlayIcon,
  [ICONS.podcast]: PodcastIcon,
  [ICONS.recipe]: RecipeIcon,
  [ICONS.fact]: FactIcon, // not used here, see CardFact
};

const CardCopy = React.memo(
  ({
    cardHighlighted,
    whiteText,
    title = "",
    category = "",
    icon,
    green = "false",
    cardText,
    type,
  }) => {
    const { useAccessibilityColor } = useContext(Settings);
    const color = whiteText ? colors.white : undefined;
    const Icon = IconMap[icon];
    const resolvedColor = color
      ? color
      : useAccessibilityColor
      ? colors.accessibilityGreen
      : colors.activiaGreen;
    return (
      <Outer cardHighlighted={cardHighlighted} type={type}>
        <Inner cardHighlighted={cardHighlighted} type={type}>
          <Category
            color={color}
            resolvedColor={resolvedColor}
            green={green}
            cardHighlighted={cardHighlighted}
            type={type}
          >
            {!!Icon && <Icon stroke={resolvedColor} size="2em" />}
            <span>{category}</span>
          </Category>
          <Title as="h2" color={color} green={green} type={type}>
            {title}
          </Title>
          <CardText type={type}>{cardText}</CardText>
        </Inner>
      </Outer>
    );
  }
);

CardCopy.propTypes = {
  cardHighlighted: PropTypes.bool,
  whiteText: PropTypes.bool,
  title: PropTypes.string,
  category: PropTypes.string,
  icon: PropTypes.oneOf(Object.values(ICONS)),
  green: PropTypes.bool,
  cardText: PropTypes.string,
  type: PropTypes.string,
};

const Outer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: ${({ type }) =>
    type === "ContentfulInteractiveCard" ? "absolute" : "relative"};
  flex-direction: ${({ cardHighlighted }) =>
    cardHighlighted ? "row" : "column"};
  justify-content: ${({ cardHighlighted }) =>
    cardHighlighted ? "flex-start" : "flex-end"};
`;

const Inner = styled.div`
  width: ${({ cardHighlighted }) =>
    cardHighlighted ? `${DIMENSIONS.doubleTextWidth}px` : "100%"};
  height: ${({ cardHighlighted, type }) =>
    cardHighlighted
      ? "100%"
      : type === "ContentfulInteractiveCard"
      ? "67%"
      : `${DIMENSIONS.singleTextHeight + 4}px`};
  display: flex;
  gap: ${({ type }) => (type === "ContentfulInteractiveCard" ? "8px" : "0")};
  flex-direction: column;
  justify-content: ${({ type }) =>
    type === "ContentfulInteractiveCard" ? "flex-start" : "center"};
  padding: ${({ type }) =>
    type === "ContentfulInteractiveCard" ? "20px" : "0 20px"};
  background: ${({ type }) =>
    type === "ContentfulInteractiveCard" ? colors.activiaGreen : "none"};
`;

const Category = styled(LabelXS)`
  position: ${({ type, cardHighlighted }) =>
    cardHighlighted
      ? "initial"
      : type === "ContentfulInteractiveCard"
      ? "absolute"
      : "relative"};
  top: ${({ type }) => (type === "ContentfulInteractiveCard" ? "12px" : "0")};
  display: flex;
  align-items: center;
  color: ${({ resolvedColor }) => resolvedColor};
  margin-bottom: 4px;
  font-weight: ${({ type }) =>
    type === "ContentfulInteractiveCard" ? fontWeights.bold : undefined};
  text-shadow: ${({ color, green }) =>
    color && !green && `1px 1px 2px ${colors.mediumShadow}`};
  color: ${({ type, cardHighlighted }) =>
    cardHighlighted
      ? colors.white
      : type === "ContentfulInteractiveCard"
      ? colors.black
      : undefined};
`;

const Title = styled(SubHeaderS)`
  color: ${({ color }) => color || colors.black};
  text-shadow: ${({ color, green }) =>
    color && !green && `1px 1px 2px ${colors.mediumShadow}`};
  font-weight: ${({ type }) =>
    type === "ContentfulInteractiveCard" ? fontWeights.black : undefined};
  font-size: ${({ type }) =>
    type === "ContentfulInteractiveCard" ? rem(18) : undefined};
  color: ${({ type }) =>
    type === "ContentfulInteractiveCard" ? colors.white : undefined};
  line-height: ${({ type }) =>
    type === "ContentfulInteractiveCard" ? lineHeight(18, 20) : undefined};
`;

const CardText = styled.p`
  color: ${({ type }) =>
    type === "ContentfulInteractiveCard" ? colors.white : undefined};
  font-size: ${rem(14)};
  font-weight: ${fontWeights.book};
  line-height: ${lineHeight(14, 16)};
`;

export default CardCopy;
