import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Picture, {
  TYPES as PICTURE_TYPES,
  FITS as PICTURE_FITS,
} from "src/atoms/Picture";
import { DIMENSIONS, SHAPES } from "./constants";

const CardImage = React.memo(({ cardHighlighted, shape, image, type }) => {
  const Component = {
    [SHAPES.scoop]: HalfSize,
    [SHAPES.circle]: cardHighlighted ? DoubleCircleSize : SingleCircleSize,
    [SHAPES.plain]: HalfSize,
    [SHAPES.layered]: FullSize,
  }[shape];

  return (
    <Component cardHighlighted={cardHighlighted} type={type}>
      {!!image && (
        <Picture
          type={PICTURE_TYPES.fixed}
          fit={
            shape === SHAPES.plain ? PICTURE_FITS.contain : PICTURE_FITS.cover
          }
          small={image}
        />
      )}
    </Component>
  );
});

CardImage.propTypes = {
  cardHighlighted: PropTypes.bool,
  shape: PropTypes.oneOf(Object.values(SHAPES)),
  image: PropTypes.object,
  type: PropTypes.string,
};

const HalfSize = styled.div`
  position: absolute;
  right: 0;
  width: ${({ cardHighlighted }) =>
    cardHighlighted
      ? `${DIMENSIONS.width + DIMENSIONS.gutter + 10}px`
      : "100%"};
  height: ${({ cardHighlighted, type }) => {
    if (type === "ContentfulInteractiveCard") {
      return cardHighlighted ? "100%" : "33%";
    }
    return cardHighlighted ? "100%" : "200px";
  }};
  overflow: hidden;
`;

const DoubleCircleSize = styled(HalfSize)`
  height: 260px;
`;

const SingleCircleSize = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  height: 170px;
`;

const FullSize = styled.div`
  width: 100%;
  height: 100%;
`;
export default CardImage;
