import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Container, Row, Col } from "react-awesome-styled-grid";
import getViewport from "src/utils/viewport";
import debounce from "src/utils/debounce";

import RichText from "src/atoms/RichText";
import CardCarousel from "src/molecules/CardCarousel";

import { mediaquery, colors, spacingValues, rem } from "src/styles/variables";

export const PUSH_PADDING = Object.freeze({
  small: spacingValues.lg * 3,
  large: spacingValues.xl * 6,
  standalone: spacingValues.xl,
});

const MD_BREAKPOINT = 768;

const EditorialPush = React.forwardRef(
  ({ text, cards, background = false, standalone = false, ...rest }, ref) => {
    const [viewport, setViewport] = useState({ width: MD_BREAKPOINT });

    const updateViewport = () => {
      setViewport(getViewport());
    };

    const debouncedResize = debounce(updateViewport, 100);

    useEffect(() => {
      updateViewport();
      window.addEventListener("resize", debouncedResize, true);

      return () => {
        window.removeEventListener("resize", debouncedResize, true);
      };
    }, []);

    return (
      <PushContainer {...rest} ref={ref} standalone={standalone}>
        {viewport.width >= MD_BREAKPOINT && background && (
          <BackgroundContainer>
            <Background />
          </BackgroundContainer>
        )}
        <Container style={{ position: "relative" }}>
          <Row>
            {text && (
              <Col md={8} lg={10} offset={{ md: 4, lg: 11 }}>
                <TextWrapper>
                  <RichText
                    enableRenderer
                    doc={text}
                    bodyColor={colors.darkGrey}
                  />
                </TextWrapper>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <CardCarousel cards={cards} />
            </Col>
          </Row>
        </Container>
      </PushContainer>
    );
  }
);

EditorialPush.propTypes = {
  text: PropTypes.object,
  cards: PropTypes.array.isRequired,
  background: PropTypes.bool,
  standalone: PropTypes.bool,
};

const PushContainer = styled.section`
  position: relative;
  padding: ${({ standalone }) =>
      standalone ? rem(PUSH_PADDING.standalone) : rem(PUSH_PADDING.small)}
    0;

  ${mediaquery.md(css`
    padding: ${({ standalone }) =>
        standalone ? rem(PUSH_PADDING.standalone) : rem(PUSH_PADDING.large)}
      0;
  `)}
`;

const TextWrapper = styled.div`
  ${mediaquery.md(css`
    text-align: center;
  `)}
`;

const BackgroundContainer = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`;

const Background = styled.div`
  position: relative;
  background: ${colors.lighterGrey};
  width: 120%;
  left: -10%;
  height: 100%;
  overflow: hidden;
  border-radius: 90% / 30vw;
`;

export default EditorialPush;
