import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors } from "src/styles/variables";
import { SHAPES } from "./constants";
import CardSingleScoop from "src/images/masks/CardSingleScoop.svg";
import CardDoubleScoop from "src/images/masks/CardDoubleScoop.svg";
import CardSingleCircle from "src/images/masks/CardSingleCircle.svg";
import CardDoubleCircle from "src/images/masks/CardDoubleCircle.svg";

const CardMask = React.memo(({ cardHighlighted, shape, type }) => {
  if (shape === SHAPES.layered) {
    return <div />; // no mask for layered shape
  }

  if (shape === SHAPES.scoop) {
    return cardHighlighted ? (
      <DoubleScoop type={type} />
    ) : (
      <SingleScoop type={type} />
    );
  }

  if (shape === SHAPES.circle) {
    return cardHighlighted ? (
      <DoubleCircle type={type} />
    ) : (
      <SingleCircle type={type} />
    );
  }
  return <div />;
});

const Mask = styled.div`
  /* ensure that the edges don't show the image behind, at the expense of some % inaccuracy */
  position: absolute;
  top: -1%;
  right: -2%;
  bottom: ${({ type }) =>
    type === "ContentfulInteractiveCard" ? "33%" : "-1%"};
  left: -2%;

  background: ${({ type }) =>
    type === "ContentfulInteractiveCard" ? colors.activiaGreen : colors.white};
  mask-repeat: no-repeat;
  mask-size: contain;
`;

const SingleScoop = styled(Mask)`
  mask-image: url(${CardSingleScoop});
  mask-position: bottom;
`;

const DoubleScoop = styled(Mask)`
  mask-image: url(${CardDoubleScoop});
  mask-position: left;
`;

const SingleCircle = styled(Mask)`
  mask-image: url(${CardSingleCircle});
`;
const DoubleCircle = styled(Mask)`
  mask-image: url(${CardDoubleCircle});
`;

CardMask.propTypes = {
  cardHighlighted: PropTypes.bool,
  shape: PropTypes.oneOf(Object.values(SHAPES)),
  type: PropTypes.string,
};

export default CardMask;
