import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Picture, {
  TYPES as PICTURE_TYPES,
  FITS as PICTURE_FITS,
} from "src/atoms/Picture";
import {
  icons,
  spacing,
  colors,
  rem,
  fontWeights,
  mediaquery,
  lineHeight,
} from "src/styles/variables";

// Utility function to calculate relative luminance
const getLuminance = (hexColor) => {
  // Remove # if present
  const hex = hexColor.replace("#", "");

  // Convert to RGB
  const r = parseInt(hex.substr(0, 2), 16) / 255;
  const g = parseInt(hex.substr(2, 2), 16) / 255;
  const b = parseInt(hex.substr(4, 2), 16) / 255;

  // Calculate luminance using the relative luminance formula
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

// Utility function to determine if we should use dark or light text
const shouldUseDarkText = (backgroundColor) => {
  if (!backgroundColor) return true;
  const luminance = getLuminance(backgroundColor);
  return luminance > 0.5;
};

const getTextColor = (backgroundColor) => {
  if (!backgroundColor) return "inherit";
  return shouldUseDarkText(backgroundColor) ? colors.black : colors.white;
};

const FullWidthCard = ({
  cardIcon,
  cardTitle,
  cardText,
  backgroundColor,
  fullWidth,
}) => {
  return (
    <Container backgroundColor={backgroundColor} fullWidth={fullWidth}>
      <ImageContainer>
        <Picture
          type={PICTURE_TYPES.fixed}
          fit={PICTURE_FITS.contain}
          small={cardIcon?.fixed}
        />
      </ImageContainer>

      <CardTitle backgroundColor={backgroundColor}>{cardTitle}</CardTitle>
      <CardText backgroundColor={backgroundColor}>{cardText}</CardText>
    </Container>
  );
};

FullWidthCard.propTypes = {
  cardIcon: PropTypes.object,
  cardTitle: PropTypes.string.isRequired,
  cardText: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  fullWidth: PropTypes.bool.isRequired,
};

const Container = styled.article`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: ${({ backgroundColor }) =>
    backgroundColor ? "none" : "1px solid currentColor"};
  padding: ${spacing.default.md};
  border-radius: 0.75rem;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: ${spacing.default.sm};
  width: 100%;

  ${mediaquery.lg`
    ${({ fullWidth }) => (fullWidth ? "width: 100%;" : "width: 65%;")}
    margin-inline: auto;
  `}
`;

const CardTitle = styled.p`
  font-size: ${rem(20)};
  font-weight: ${fontWeights.medium};
  color: ${({ backgroundColor }) => getTextColor(backgroundColor)};
`;

const CardText = styled.p`
  font-size: ${rem(16)};
  color: ${({ backgroundColor }) => getTextColor(backgroundColor)};
  grid-column: 2;
  line-height: ${lineHeight(16, 24)};
`;

const ImageContainer = styled.div`
  width: ${icons.l};
  height: ${icons.l};
  border-radius: 999px;
  overflow: hidden;
`;

export default FullWidthCard;
