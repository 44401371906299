import React from "react";
import { Container, Row, Col } from "react-awesome-styled-grid";

import FullWidthCard from "./FullWidthCard";
import Spacer from "src/organisms/PageComponentList/Spacer";

const PageComponent = (props) => (
  <Container>
    <Row as="section">
      <Col md={10} offset={{ md: 3 }}>
        <Spacer />
        <FullWidthCard {...props} />
        <Spacer />
      </Col>
    </Row>
  </Container>
);

export default PageComponent;
