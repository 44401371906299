import React, { useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";
import {
  mediaquery,
  icons,
  colors,
  fontWeights,
  rem,
} from "src/styles/variables";
import FloatingButton, { SIZES } from "src/molecules/FloatingButton";
import Picture, {
  TYPES as PICTURE_TYPES,
  FITS as PICTURE_FITS,
} from "src/atoms/Picture";
import Close from "src/atoms/Vectors/Standard/Close";
import Link, { TYPES } from "src/atoms/Link";

const CardPopup = ({
  setIsPopupVisible,
  id,
  popupTitle,
  popupText,
  popupImage,
  cta,
}) => {
  const popupRef = useRef(null);

  useEffect(() => {
    if (popupRef.current) {
      popupRef.current.setAttribute("popover", "auto");

      requestAnimationFrame(() => {
        popupRef.current?.showPopover();
      });
    }

    const handleEscape = (e) => {
      if (e.key === "Escape") {
        handleClose();
      }
    };

    const handleBeforeToggle = (e) => {
      if (e.newState === "closed") {
        setIsPopupVisible(false);
      }
    };

    document.addEventListener("keydown", handleEscape);
    popupRef.current?.addEventListener("beforetoggle", handleBeforeToggle);

    return () => {
      document.removeEventListener("keydown", handleEscape);
      popupRef.current?.removeEventListener("beforetoggle", handleBeforeToggle);
    };
  }, []);

  const handleClose = () => {
    if (popupRef.current) {
      popupRef.current.hidePopover();
      setIsPopupVisible(false);
    }
  };

  return (
    <Popup ref={popupRef} popover="auto" id={id}>
      <PopupTitle>{popupTitle}</PopupTitle>
      <PopupText>{popupText}</PopupText>
      {popupImage && (
        <ImageContainer width={popupImage.width} height={popupImage.height}>
          <Picture
            type={PICTURE_TYPES.fixed}
            small={popupImage}
            fit={PICTURE_FITS.contain}
          />
        </ImageContainer>
      )}
      {cta && <Cta {...cta} type={TYPES.button} />}
      <CloseButtonContainer>
        <FloatingButton onClick={handleClose} size={SIZES.small}>
          <Close size={icons.s} />
        </FloatingButton>
      </CloseButtonContainer>
    </Popup>
  );
};

CardPopup.propTypes = {
  setIsPopupVisible: PropTypes.func,
  id: PropTypes.string,
  popupTitle: PropTypes.string,
  popupText: PropTypes.string,
  popupImage: PropTypes.object,
  cta: PropTypes.object,
};

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -45%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
`;

const Cta = styled(Link)`
  width: 100%;
  ${mediaquery.md(`
    width: fit-content;
  `)}
`;

const Popup = styled.div`
  display: grid;
  gap: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  background-color: ${colors.darkGreen};
  color: ${colors.white};
  padding: 30px;
  border-radius: 16px;
  animation: ${fadeIn} 0.3s ease-out;
  transition: opacity 0.3s ease-out, transform 0.3s ease-out allow-discrete;
  max-width: 500px;
  box-shadow: 0px 4px 10px ${colors.mediumShadow};

  ::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    ${mediaquery.md`
        background-color: rgba(0, 0, 0, 0);
    `};
  }

  &:not([popover]) {
    display: none;
  }

  &[popover] {
    position: fixed;
    inset: unset;
    top: 50%;
    left: 50%;
  }
`;

const ImageContainer = styled.div`
  aspect-ratio: ${({ width, height }) => width / height};
  border-radius: calc(16px / 2);
  overflow: hidden;
`;

const PopupTitle = styled.h2`
  font-weight: ${fontWeights.black};
  font-size: ${rem(18)};
  color: ${colors.white};
  width: 90%;
`;

const PopupText = styled.p``;

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  background-color: transparent;
`;

export default CardPopup;
