import React, { useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import styled, { keyframes, css } from "styled-components";
import { navigate } from "gatsby";

import { colors, fontWeights, outline } from "src/styles/variables";
import { DIMENSIONS, SHAPES, ICONS, LOAD_ANIMATIONS } from "./constants";
import CardFact from "./CardFact";
import CardImage from "./CardImage";
import CardMask from "./CardMask";
import CardCopy from "./CardCopy";
import CardPopup from "./CardPopup";
import Page from "src/stores/Page";
import Settings from "src/stores/Settings";
import { ANIMATION_DURATION } from "src/atoms/CardNavigationTransition";
import { animations } from "src/atoms/Illustration";
import getLocalizedSlug from "src/utils/getLocalizedSlug";
import TrackerPanel from "../TrackerResults/TrackerPanel";
import ArrowRight from "src/atoms/Vectors/Arrows/ArrowRight";

const CardRaw = ({
  title,
  fact,
  category,
  illustration,
  disclaimer,
  image,
  shape = SHAPES.scoop,
  cardHighlighted = false,
  icon,
  slug,
  loadAnimated = false,
  green = false,
  backgroundColor,
  percent,
  trackPosition = undefined,
  carryState,
  cardText,
  popupText,
  popupTitle,
  type,
  cta,
  popupImage,
  id,
  clickIndicatorLabel,
  clickIndicatorIcon,
  ...rest
}) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const {
    cardNavigating: [, setCardNavigating],
  } = useContext(Page);
  const { locale } = useContext(Settings);

  const outer = useRef(null);

  const handleClick = () => {
    if (type === "ContentfulInteractiveCard" && !isPopupVisible) {
      setIsPopupVisible(true);
    }
  };

  return (
    <Outer
      {...rest}
      cardHighlighted={cardHighlighted}
      ref={outer}
      loadAnimated={loadAnimated}
      shape={shape}
      type={type}
      {...(slug
        ? {
            clickable: true,
            tabIndex: 0,
            href: getLocalizedSlug(slug, locale),
            as: "a",
            onClick: (e) => {
              e.preventDefault();
              if (trackPosition) {
                trackPosition();
              }
              const { top, left } = outer.current.getBoundingClientRect();
              setCardNavigating({
                top,
                left,
              });
              setTimeout(() => {
                const params = window.location.search || "";
                navigate(getLocalizedSlug(slug, locale), {
                  state: {
                    ...carryState,
                    from: "card",
                    prev: (history.state && history.state.prev) || "/",
                    params: params,
                  },
                });
              }, ANIMATION_DURATION);
            },
          }
        : {})}
    >
      <Inner
        cardHighlighted={cardHighlighted}
        loadAnimated={loadAnimated}
        green={green}
      >
        {shape === SHAPES.fact && (
          <CardFact
            front={title}
            back={fact}
            category={category.name}
            illustration={illustration}
            disclaimer={disclaimer}
          />
        )}
        {shape !== SHAPES.fact && shape !== SHAPES.trackerCategory && (
          <>
            {!!image && (
              <Layer>
                <CardImage
                  cardHighlighted={cardHighlighted}
                  shape={shape}
                  image={image}
                  type={type}
                />
              </Layer>
            )}
            <Layer>
              <CardMask
                cardHighlighted={cardHighlighted}
                shape={shape}
                type={type}
              />
            </Layer>
            {!!(title || category || icon) && (
              <Layer>
                <CardCopy
                  cardHighlighted={cardHighlighted}
                  whiteText={shape === SHAPES.layered || green}
                  title={title}
                  cardText={cardText}
                  category={category && category.name}
                  icon={icon}
                  green={green}
                  type={type}
                />
              </Layer>
            )}
          </>
        )}
        {shape === SHAPES.trackerCategory && (
          <TrackerPanel
            title={title}
            backgroundColor={backgroundColor}
            percent={percent}
            icon={icon}
          />
        )}
        {isPopupVisible && (
          <CardPopup
            popupText={popupText}
            popupTitle={popupTitle}
            setIsPopupVisible={setIsPopupVisible}
            id={id}
            cta={cta}
            popupImage={popupImage}
          />
        )}
      </Inner>
      {type === "ContentfulInteractiveCard" && (
        <ClickIndicator onClick={handleClick}>
          <ClickIndicatorLabel>{clickIndicatorLabel}</ClickIndicatorLabel>
          {clickIndicatorIcon ? (
            <ClickIndicatorIcon src={clickIndicatorIcon.file.url} />
          ) : (
            <ArrowRight
              fill={colors.activiaGreen}
              css={{ width: "3em", display: "flex" }}
            />
          )}
        </ClickIndicator>
      )}
    </Outer>
  );
};

CardRaw.propTypes = {
  title: PropTypes.string,
  fact: PropTypes.string,
  category: PropTypes.object,
  illustration: PropTypes.oneOf(Object.keys(animations)),
  image: PropTypes.object,
  shape: PropTypes.oneOf(Object.values(SHAPES)),
  cardHighlighted: PropTypes.bool,
  icon: PropTypes.oneOf(Object.values(ICONS)),
  slug: PropTypes.string,
  loadAnimated: PropTypes.bool,
  green: PropTypes.bool,
  disclaimer: PropTypes.string,
  trackPosition: PropTypes.func,
  percent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backgroundColor: PropTypes.string,
  carryState: PropTypes.object,
  cardText: PropTypes.string,
  popupText: PropTypes.string,
  popupTitle: PropTypes.string,
  type: PropTypes.string,
  cta: PropTypes.object,
  popupImage: PropTypes.object,
  id: PropTypes.string,
  clickIndicatorLabel: PropTypes.string,
  clickIndicatorIcon: PropTypes.object,
};

const scaleUp = keyframes`
  0% {
    transform: scale(0);
    opacity: .2;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const scaleUpAnimation = css`
  animation: ${scaleUp} ${LOAD_ANIMATIONS.scale} cubic-bezier(0.47, 0, 0.37, 1);
`;

const Outer = styled.div`
  cursor: ${({ clickable }) => (clickable ? "pointer" : "default")};
  outline: 0;
  user-select: none;
  position: relative;
  display: block;
  width: ${({ cardHighlighted }) =>
    cardHighlighted
      ? `${DIMENSIONS.width * 2 + DIMENSIONS.gutter}px`
      : `${DIMENSIONS.width}px`};
  height: ${`${DIMENSIONS.height}px`};
  border-radius: 16px;
  background: ${colors.white};

  ${({ loadAnimated }) => (loadAnimated ? scaleUpAnimation : null)};
  box-shadow: ${({ shape }) =>
    shape === SHAPES.fact ? null : `0 6px 10px 2px ${colors.translucidBlack}`};

  /* Removing because design asked for it */
  /*
  &:focus {
    outline: none;
    box-shadow: ${outline};
  } */
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const fadeInAnimation = css`
  opacity: 0;
  animation: ${fadeIn} ${LOAD_ANIMATIONS.fade} cubic-bezier(0.47, 0, 0.37, 1)
    ${LOAD_ANIMATIONS.fadeDelay} forwards;
`;

const Inner = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  background: ${({ green }) => (green ? colors.activiaGreen : colors.white)};
  ${({ loadAnimated }) => (loadAnimated ? fadeInAnimation : null)};

  ${({ green, cardHighlighted }) =>
    green &&
    cardHighlighted &&
    css`
      img {
        object-position: top right !important;
        width: 65% !important;
        right: 0;
        left: auto !important;
      }
    `}
`;

const Layer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const ClickIndicator = styled.button`
  position: absolute;
  bottom: -0.2rem;

  right: -5em;
  background-color: ${colors.white};
  color: ${colors.activiaGreen};
  padding-left: 12px;
  padding-block: 6px;
  font-weight: ${fontWeights.medium};
  border-radius: 16px;
  z-index: 100;
  display: flex;
  align-items: center;
  text-align: left;

  box-shadow: 0 6px 10px 2px ${colors.translucidBlack};
  cursor: pointer;
  max-width: min-content;
  word-wrap: break-word; /* Ensures words break if necessary */
  white-space: normal; /* Allows wrapping */
  text-wrap: balance;
`;

const ClickIndicatorIcon = styled.img`
  width: 2em;
  display: flex;
  margin-right: 8px;
`;

const ClickIndicatorLabel = styled.span`
  width: 7em;
`;

export default CardRaw;
