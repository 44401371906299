import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Settings from "src/stores/Settings";
import { Row, Col, Container } from "react-awesome-styled-grid";
import { spacing, icons, mediaquery } from "src/styles/variables";
import Link, { TYPES } from "src/atoms/Link";
import RichText from "src/atoms/RichText";
import FloatingButton, { SIZES } from "src/molecules/FloatingButton";
import Close from "src/atoms/Vectors/Standard/Close";
import Checkbox from "src/atoms/Checkbox";
import Button from "src/atoms/Button";

import { setCookie, HCP_COOKIE_NAME } from "src/utils/hcp";

const Popup = ({
  popupId,
  popupContent,
  confirmationText,
  showCloseButton,
  acceptHcpCta,
}) => {
  const { closeTitle } = useContext(Settings).translations;
  const hcpPopupId = useContext(Settings).hcpPopup?.popupId;

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (!confirmationText) {
      setIsChecked(true);
    }
  }, [confirmationText]);

  const closePopup = () => {
    document.body.classList.remove("overflow-hidden");
    const popupElement = document.getElementById(popupId);
    if (popupElement) {
      popupElement.classList.remove("active");
    }
  };

  const handleAcceptClick = (e, link) => {
    if (isChecked) {
      if (popupId === hcpPopupId) {
        setCookie(HCP_COOKIE_NAME, "true");
      }

      closePopup();

      if (link) {
        window.location.href = link;
      }
    }
  };

  const updateIsChecked = (checked) => {
    setIsChecked(checked);
  };

  const hasLessThanTwoCtas = (content) => {
    return (
      content.filter((item) => item.__typename === "ContentfulCta").length < 2
    );
  };

  return (
    <Overlay id={popupId}>
      <ContainerStyled>
        <RowStyled>
          <PopupContainer xs={12} md={10} lg={14}>
            <PopupBackground>
              {showCloseButton && popupId !== hcpPopupId && (
                <CloseLinkOuter>
                  <a onClick={closePopup} title={closeTitle}>
                    <FloatingButton size={SIZES.medium}>
                      <Close size={icons.m} />
                    </FloatingButton>
                  </a>
                </CloseLinkOuter>
              )}
              {popupContent.map((popupContentItem, index_) => (
                <ColStyled
                  className={`${
                    popupContentItem.__typename === "ContentfulCta"
                      ? "popup-button"
                      : ""
                  }`}
                  key={index_}
                >
                  {(popupContentItem.__typename === "ContentfulTextModule" && (
                    <RichText
                      as="div"
                      enableRenderer
                      doc={popupContentItem.value}
                      alignment={popupContentItem.alignment}
                    />
                  )) ||
                    null}
                </ColStyled>
              ))}
              {hasLessThanTwoCtas(popupContent) && popupId !== hcpPopupId && (
                <CheckboxContainer>
                  <Checkbox
                    id={popupId + "_confirmation"}
                    label={confirmationText}
                    align="left"
                    setIsChecked={updateIsChecked}
                  />
                </CheckboxContainer>
              )}
              <ButtonGroup className="button-container-wrapper">
                {acceptHcpCta && popupId === hcpPopupId && (
                  <ButtonContainer
                    onClick={(e) => handleAcceptClick(e)}
                    className={
                      hasLessThanTwoCtas(popupContent) && !isChecked
                        ? "inactive"
                        : ""
                    }
                  >
                    <Button>{acceptHcpCta.label}</Button>
                  </ButtonContainer>
                )}
                {popupContent
                  .filter(
                    (popupContentItem) =>
                      popupContentItem.__typename === "ContentfulCta"
                  )
                  .map((popupContentItem, index_) => (
                    <ButtonContainer
                      key={index_}
                      className={
                        hasLessThanTwoCtas(popupContent) && !isChecked
                          ? "inactive"
                          : ""
                      }
                    >
                      <Link {...popupContentItem} type={TYPES.button} />
                    </ButtonContainer>
                  ))}
              </ButtonGroup>
            </PopupBackground>
          </PopupContainer>
        </RowStyled>
      </ContainerStyled>
    </Overlay>
  );
};

Popup.propTypes = {
  popupId: PropTypes.string,
  popupContent: PropTypes.array,
  confirmationText: PropTypes.string,
  showCloseButton: PropTypes.bool,
  acceptHcpCta: PropTypes.object,
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: -10;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  transition: all 0.2s, z-index 0s ease 0.2s;

  &.active {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transition: all 0.2s, z-index 0s;
  }
`;

const ContainerStyled = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RowStyled = styled(Row)`
  justify-content: center;
  max-height: 100vh;
`;

const PopupContainer = styled(Col)`
  padding: 5vh 0;
`;

const PopupBackground = styled.div`
  background: #fff;
  border-radius: 16px;
  padding: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const CloseLinkOuter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;

  ${mediaquery.md(css`
    position: absolute;
    top: -1vh;
    right: -60px;
  `)}
`;

const ColStyled = styled(Col)`
  margin: ${spacing.stack.xl};
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  &.popup-button {
    order: 2;
  }
`;

const CheckboxContainer = styled.div`
  margin: 2.5rem 0 1.5rem;
  display: flex;
  justify-content: flex-start;

  ${mediaquery.md(css`
    justify-content: center;
  `)}
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  ${mediaquery.md(css`
    flex-direction: row;
  `)}
`;

const ButtonContainer = styled.div`
  display: flex;

  ${mediaquery.md(css`
    justify-content: center;
  `)}

  a {
    transition: all 0.3s;
    white-space: normal;
    height: auto;
    min-height: 50px;

    span {
      line-height: 1.5;
      padding: 10px 0;
    }
  }

  &.inactive {
    a {
      pointer-events: none;
      opacity: 0.3;
    }
  }
`;

export default Popup;
