export const HCP_COOKIE_NAME = "hcp_accepted";

// Create hcp cookie and set to false by default
export const setCookie = (name, value) => {
  const date = new Date();
  date.setTime(date.getTime() + 100 * 365 * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/;secure;samesite=strict`;
};

// Function to get cookie value
export const getCookie = (name) => {
  const cookies = document.cookie.split(";");
  for (let cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split("=").map((c) => c.trim());
    if (cookieName === name) {
      return cookieValue;
    }
  }
  return null;
};
