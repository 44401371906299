import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Container, Row, Col } from "react-awesome-styled-grid";
import PageContext from "src/stores/Page";
import Settings from "src/stores/Settings";
import { icons } from "src/styles/variables";
import HeaderSpace from "src/organisms/Header/Spacer";
import Breadcrumb from "src/atoms/Breadcrumb";
import HeroSpace from "src/molecules/Hero/Spacer";
import TrackerSpace from "src/molecules/Tracker/Spacer";
import FloatingButton, { SIZES } from "src/molecules/FloatingButton";
import Close from "src/atoms/Vectors/Standard/Close";
import mappers from "./mappers";

const Modules = ({ modules, onClose, pageType, breadcrumbItems }) => {
  const { closeTitle } = useContext(Settings).translations;
  const {
    visibleHeader: [isHeaderVisible],
  } = useContext(PageContext);

  const moduleWithContactEmail = modules.find(
    (module) =>
      module.__typename === "ContentfulTextModule" &&
      module.value.raw.includes('"uri":"mailto:')
  );

  useEffect(() => {
    if (moduleWithContactEmail) {
      const contactEmails = window.document.querySelectorAll(
        "a[href*='mailto:']"
      );
      for (const contactEmail of contactEmails) {
        contactEmail.addEventListener(
          "click",
          () => {
            if (typeof window.tC.event.contact_request_email === "function") {
              window.tC.event.contact_request_email(this, {});
            }
          },
          { once: true }
        );
      }
    }
  }, [moduleWithContactEmail]);

  return modules.map((componentProps, key) => {
    const child = mappers[componentProps.__typename];
    if (!child) {
      console.log(
        `Skipping unsupported page component "${
          componentProps.__typename
        }", expecting one of: ${Object.keys(mappers).join(", ")}`
      );
      return null;
    }
    const { Component, mapper } = child;

    const uniqueKey = componentProps.id
      ? `${componentProps.__typename}-${componentProps.id}-${key}`
      : `${componentProps.__typename}-${key}`;

    if (key === 0) {
      if (
        componentProps.__typename !== "ContentfulHero" &&
        componentProps.__typename !== "ContentfulTracker"
      ) {
        return (
          <HeaderSpace key={uniqueKey}>
            <Container>
              <Breadcrumb />
            </Container>
            <Component {...mapper(componentProps)} />
            {onClose && <CloseButton onClick={onClose} title={closeTitle} />}
          </HeaderSpace>
        );
      }

      return (
        <React.Fragment key={uniqueKey}>
          <Component {...mapper(componentProps)} />
          {componentProps.__typename !== "ContentfulTracker" && (
            <Container>
              <Breadcrumb />
            </Container>
          )}
          {onClose && (
            <CloseButton
              onClick={onClose}
              isHeaderVisible={isHeaderVisible}
              title={closeTitle}
            />
          )}
        </React.Fragment>
      );
    }
    if (
      componentProps.__typename === "ContentfulTheGutLifePreview" &&
      modules[Math.max(0, key - 1)].__typename === "ContentfulHero"
    ) {
      return (
        <HeroSpace key={uniqueKey}>
          <Component {...mapper(componentProps)} />
        </HeroSpace>
      );
    }
    if (modules[Math.max(0, key - 1)].__typename === "ContentfulTracker") {
      return (
        <TrackerSpace key={uniqueKey}>
          <Component {...mapper(componentProps)} />
        </TrackerSpace>
      );
    }

    return (
      <React.Fragment key={uniqueKey}>
        <Component {...mapper(componentProps)} pageType={pageType} />
      </React.Fragment>
    );
  });
};

export const CloseButton = ({ onClick, isHeaderVisible, title }) => {
  return (
    <StickyContainer isHeaderVisible={isHeaderVisible}>
      <Row>
        <Col xs={8} md={14}>
          <CloseLinkOuter>
            <a onClick={onClick} title={title}>
              <FloatingButton size={SIZES.medium}>
                <Close size={icons.m} />
              </FloatingButton>
            </a>
          </CloseLinkOuter>
        </Col>
      </Row>
    </StickyContainer>
  );
};

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isHeaderVisible: PropTypes.bool,
  title: PropTypes.string,
};

const StickyContainer = styled(Container)`
  position: sticky;
  top: ${({ isHeaderVisible }) => (isHeaderVisible ? "100px" : "50px")};
  z-index: 1;
`;

const CloseLinkOuter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

Modules.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func,
};

export default Modules;
