import React from "react";
import PropTypes from "prop-types";
import Picture, {
  TYPES as PICTURE_TYPES,
  FITS as PICTURE_FITS,
} from "src/atoms/Picture";
import styled, { css } from "styled-components";
import { icons, colors, rem, spacing, mediaquery } from "src/styles/variables";
const GridModule = ({ cards }) => {
  if (!cards || !cards.length) {
    return null;
  }

  const isSingleCard = cards.length === 1;

  return (
    <GridModuleContainer isSingleCard={isSingleCard}>
      {cards.map((card, index) => (
        <GridCardContainer key={index} isSingleCard={isSingleCard}>
          <GridCardImageContainer>
            {card.cardIcon && (
              <Picture
                type={PICTURE_TYPES.fixed}
                fit={PICTURE_FITS.contain}
                small={card.cardIcon.fixed}
              />
            )}
          </GridCardImageContainer>
          <GridCardTitle>{card.cardTitle}</GridCardTitle>

          <GridCardText>{card.cardText}</GridCardText>
        </GridCardContainer>
      ))}
    </GridModuleContainer>
  );
};

GridModule.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      cardIcon: PropTypes.object,
      cardTitle: PropTypes.string,
      cardText: PropTypes.string,
    })
  ).isRequired,
};

const GridModuleContainer = styled.ul`
  padding-bottom: ${spacing.default.xl};

  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;

  ${mediaquery.lg(css`
    grid-template-columns: repeat(2, 1fr);
    position: relative;

    ${({ isSingleCard }) =>
      !isSingleCard &&
      css`
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 1px;
          height: 100%;
          background-color: ${colors.activiaGreen};
        }
      `}
  `)}

  gap: ${spacing.default.xl};

  border-bottom: 1px solid ${colors.activiaGreen};
`;

const GridCardContainer = styled.li`
  display: grid;

  grid-template-rows: subgrid;
  grid-row: span 2;

  grid-template-columns: auto 1fr;
  gap: ${spacing.default.sm};

  ${mediaquery.lg(css`
    ${({ isSingleCard }) =>
      isSingleCard &&
      css`
        grid-column: span 2;
      `}
  `)}
`;

const GridCardImageContainer = styled.div`
  width: ${icons.l};
  height: ${icons.l};
  border-radius: 999px;
  overflow: hidden;
  margin: auto;
`;

const GridCardTitle = styled.p`
  color: ${colors.activiaGreen};
  font-size: ${rem(20)};
  align-content: center;
`;

const GridCardText = styled.p`
  grid-column: 2;
`;

export default GridModule;
